import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setUserHealthScoreDataInLocalStorage,
  getUserHealthScoreDataFromLocalStorage,
  removeOnboardingKeysForMiddleFlow,
} from "configs/utils";

import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";
import Switch from "components/formComponents/switch/Switch";

import ArrowLeft from "assets/images/arrowLeft.svg";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";

import { SidebarSteps } from "../data";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "../OnBoardingPartThree.styles";

const HealthScoreDetermination = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const healthScoreData = useSelector((state) => state.auth.healthScoreData);

  const [question1, setQuestion1] = useState(
    // healthScoreData ? healthScoreData.question1 : false
    false
  );
  const [question2, setQuestion2] = useState(
    // healthScoreData ? healthScoreData.question2 : false
    false
  );
  const [question3, setQuestion3] = useState(
    // healthScoreData ? healthScoreData.question3 : false
    false
  );
  const [question4, setQuestion4] = useState(
    // healthScoreData ? healthScoreData.question4 : false
    false
  );
  const [question5, setQuestion5] = useState(
    // healthScoreData ? healthScoreData.question5 : false
    false
  );

  useEffect(() => {
    const HID =
      getUserHealthScoreDataFromLocalStorage()?.healthScoreInvestigation;

    if (!HID) return;

    setQuestion1(HID?.question1);
    setQuestion2(HID?.question2);
    setQuestion3(HID?.question3);
    setQuestion4(HID?.question4);
    setQuestion5(HID?.question5);
  }, []);

  const QuestionData = [
    {
      id: "question1",
      question: "healthScore.investigation.question1",
      checked: question1,
      func: setQuestion1,
    },
    {
      id: "question2",
      question: "healthScore.investigation.question2",
      checked: question2,
      func: setQuestion2,
    },
    {
      id: "question3",
      question: "healthScore.investigation.question3",
      checked: question3,
      func: setQuestion3,
    },
    {
      id: "question4",
      question: "healthScore.investigation.question4",
      checked: question4,
      func: setQuestion4,
    },
    {
      id: "question5",
      question: "healthScore.investigation.question5",
      checked: question5,
      func: setQuestion5,
    },
  ];

  const handleBack = () => {
    history.push("/onboarding/nutrition/done");
  };

  const handleNext = () => {
    const questionsData = {
      question1,
      question2,
      question3,
      question4,
      question5,
    };

    setUserHealthScoreDataInLocalStorage({
      ...getUserHealthScoreDataFromLocalStorage(),
      healthScoreInvestigation: questionsData,
    });
    // dispatch(AuthActions.setHealthScoreData(questionsData));
    history.push("/onboarding/healthscore/priorities");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );

    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={4} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Tipps & Tricks
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Grundlagen einer gesunden Ernährung.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-investigation-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Deinen aktuellen Health Score ermitteln
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Einen kleinen Einblick in deine aktuelle Ernährung.
                </p>
                <br />
                <div className="health-score-investigation-checks d-flex flex-column gap-3">
                  {QuestionData.map((item) => {
                    return (
                      <S.QuestionBoxContainer key={item.id}>
                        <p className="text-size-16-24 text-dark-2 font-rubik-regular my-0">
                          <TranslationMessage id={item.question} />
                        </p>
                        <Switch
                          small
                          switchedOn={item.checked}
                          onChange={() => item.func(!item.checked)}
                        />
                      </S.QuestionBoxContainer>
                    );
                  })}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default HealthScoreDetermination;
