import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import emailjs from "@emailjs/browser";
import { Select, TimePicker } from "antd";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import dayjs from 'dayjs'

import Input from "components/formComponents/input/Input";
import TextArea from "components/formComponents/textArea/TextArea";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Button from "components/formComponents/button/Button";
import { MENUS } from "./data";
import * as S from "./support.styles";

const Support = () => {
  const [IsEmailSending, setIsEmailSending] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const trainerData = useSelector((state) => state.auth.trainerData);

  const initialValues = {
    prename: trainerData?.prename,
    surname: trainerData?.surname,
    FranchiseID: trainerData?.FranchiseID,
    reference: "",
    news: "",
    operatingSystemPhone: '',
    operatingSystemComputer: '',
    softwareVersion: '',
    customerId: '',
    issueOccuredDate: '',
    issueOccuredTime: '',
    issueDescription: '',
    fileUploadsUrl: '',
  };

  const validationSchema = object().shape({
    FranchiseID: string()
      .typeError("You must enter a String")
      .required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    prename: string().typeError("You must enter a String").required("Required"),
    reference: string()
      .typeError("You must enter a String")
      .required("Required"),
    news: string().typeError("You must enter a String").required("Required"),
    operatingSystemPhone: string(),
    operatingSystemComputer: string(),
    softwareVersion: string(),
    customerId: string(),
    issueOccuredDate: string(),
    issueOccuredTime: string(),
    issueDescription: string().required(),
    fileUploadsUrl: string()
  });

  const onFormSubmit = (values, { resetForm }) => {
    setIsEmailSending(true);
    let templateParams = {
      subject: "Support",
      reference: values.reference,
      news: values.news,
      franchiseId: values.FranchiseID,
      prename: values.prename,
      surname: values.surname,
      fromName: `${values.prename} ${values.surname}`,
      email: trainerData?.email,
      senderEmail: trainerData?.email,
      recipientEmail: "Backoffice@foodiary.app",
      operatingSystemPhone: values.operatingSystemPhone,
      operatingSystemComputer: values.operatingSystemComputer,
      softwareVersion: values.softwareVersion,
      customerId: values.customerId,
      issueOccuredDate: values.issueOccuredDate,
      issueOccuredTime: values.issueOccuredTime,
      issueDescription: values.issueDescription,
      fileUploadsUrl: values.fileUploadsUrl,
    };

    emailjs
      .send(
        "service_srrcjm9",
        "template_d49rcah",
        templateParams,
        "uowiKoxYT_i1TYcoP"
      )
      .then(
        function (response) {
          setIsEmailSending(false);
          setIsSuccess(true);
          resetForm();
          const timer = setTimeout(() => {
            setIsSuccess(false);
            clearTimeout(timer);
          }, 3000);
        },
        function (error) {
          setIsEmailSending(false);

          setIsError(true);

          const timer = setTimeout(() => {
            setIsError(false);
            clearTimeout(timer);
          }, 3000);
        }
      );
  };

  const phoneOsOptions = [
    { label: 'Android', value: 'Android' },
    { label: 'Apple', value: 'Apple' },
    { label: 'Windows', value: 'Windows' },
  ]

  const ComputerOsOptions = [
    { label: 'MacOS', value: 'MacOS' },
    { label: 'Windows', value: 'Windows' },
    { label: 'Anderes', value: 'Anderes' },
  ]

  const subjectOptions = [
    { label: 'Ernährungsplan - App', value: 'Ernährungsplan - App' },
    { label: 'Foodiary Coach Dashboard', value: 'Foodiary Coach Dashboard' },
    { label: 'Lernacademy', value: 'Lernacademy' },
    { label: 'Buchhaltung', value: 'Buchhaltung' },
    { label: 'Sonstiges', value: 'Sonstiges' },
  ]

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <S.TrainerProfileDetailsInnerContainer>
                  <S.TrainerProfileDetailsSectionHeading>
                    <span>Ticket eröffnen</span>
                    <button type="submit" disabled={IsEmailSending} className="btn-dark">
                      Ticket absenden
                    </button>
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Sende dein anliegen direkt an unser Support Team
                  </S.TrainerProfileDetailsSectionSubHeading>
                  {IsSuccess && (
                    <S.SuccessAlert>
                      Email has been sent successfully!
                    </S.SuccessAlert>
                  )}
                  {IsError && <S.ErrorAlert>Error! Try again.</S.ErrorAlert>}

                  {/* <br /> */}
                  <S.Divider />
                  <br />
                  <br />

                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Franchise-id
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        disabled
                        style={{ width: "100%" }}
                        name="FranchiseID"
                        onChange={handleChange}
                        value={values.FranchiseID}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Vorname
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        disabled
                        style={{ width: "100%" }}
                        name="prename"
                        onChange={handleChange}
                        value={values.prename}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Nachname
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        disabled
                        style={{ width: "100%" }}
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                      />
                    </S.FormControl>
                  </S.InlineControls>
                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      Betreff
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Select
                      onChange={value => setFieldValue('reference', value)}
                      style={{ width: '100%', height: '40.5px' }}
                      options={subjectOptions}
                      placeholder='Um welches Anliegen handelt es sich?'
                    />
                    {/* <Input
                      className="input-custom copy-text"
                      style={{ width: "100%" }}
                      name="reference"
                      onChange={handleChange}
                      value={values.reference}
                      placeholder='Um welches Anliegen handelt es sich?'
                    /> */}
                  </S.FormControl>


                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Welches Betriebssytem hat das betroffene Handy ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Select
                        onChange={value => setFieldValue('operatingSystemPhone', value)}
                        style={{ width: '100%', height: '40.5px' }}
                        options={phoneOsOptions}
                        placeholder='Betriebssystem auswählen'
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Welches Betriebssystem hat der PC ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Select
                        onChange={value => setFieldValue('operatingSystemComputer', value)}
                        style={{ width: '100%', height: '40.5px' }}
                        options={ComputerOsOptions}
                        placeholder='Betriebssystem auswählen'
                      />
                    </S.FormControl>
                  </S.InlineControls>
                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      Welche Softwareversion wird auf dem Handy verwendet ?
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom copy-text"
                      style={{ width: "100%",  }}
                      name="softwareVersion"
                      onChange={handleChange}
                      value={values.softwareVersion}
                      placeholder='z.B. IOS 18 oder Android 15.0'
                    />
                  </S.FormControl>
                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      Wie lautet die Kunden ID oder E-Mail Adresse ?
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom copy-text"
                      style={{ width: "100%",  }}
                      name="customerId"
                      onChange={handleChange}
                      value={values.customerId}
                      placeholder='klient@foodiary.app'
                    />
                  </S.FormControl>
                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        An welchem Tag ist das Anliegen aufgetreten ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <DatePicker
                        value={values.issueOccuredDate}
                        onDateChange={date => setFieldValue('issueOccuredDate', date)}
                        placeholder={'DD.MM.YYYY'}
                        format={'DD.MM.YYYY'}
                        name="issueOccuredDate"
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Zu welcher Uhrzeit ist das Anliegen aufgetreten ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <TimePicker
                        onChange={fullDateTime => (
                          setFieldValue('issueOccuredTime', dayjs(fullDateTime).format('HH:mm:ss'))
                        )}
                        style={{ width: '100%', height: '40.5px', borderRadius: '10px' }}
                        placeholder="Zeit auswählen"
                      />
                    </S.FormControl>
                  </S.InlineControls>

                  <S.FormControl>
                    <div className="textarea-box">
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Bitte beschreibe das Anliegen so detailliert wie möglich ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <TextArea
                        rows={10}
                        name="issueDescription"
                        onChange={handleChange}
                        value={values.issueDescription}
                        placeholder='Wie ist die Situation entstanden? Gab es einen Crash der Software? Welche Daten wurden eingegeben ?'
                      />
                    </div>
                  </S.FormControl>

                  <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        Gibt es Bilder oder Videos zu deinem Anliegen ?
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <S.TrainerProfileDetailsSectionDescription>
                        Bitte Lade deine Videos und Bilder auf <a target="_blank" href="https://wetransfer.com">https://wetransfer.com</a> und teile den Link mit uns
                      </S.TrainerProfileDetailsSectionDescription>
                      {/* <S.Explanation>
                        Bitte Lade deine Videos und Bilder auf <a target="_blank" href="https://wetransfer.com">https://wetransfer.com</a> und teile den Link mit uns
                      </S.Explanation> */}
                      <Input
                        className="input-custom copy-text"
                        style={{ width: "100%" }}
                        name="fileUploadsUrl"
                        onChange={handleChange}
                        value={values.fileUploadsUrl}
                        placeholder='http://wetransfer.com/'
                      />
                    </S.FormControl>


                  <S.FormControl>
                    <div className="textarea-box">
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        NACHRICHT
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <TextArea
                        rows={10}
                        name="news"
                        onChange={handleChange}
                        value={values.news}
                        placeholder='Was möchtest du uns mitteilen ?'
                      />
                    </div>
                  </S.FormControl>
                </S.TrainerProfileDetailsInnerContainer>
              </form>
            )}
          </Formik>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default Support;
