import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setUserHealthScoreDataInLocalStorage,
  getUserHealthScoreDataFromLocalStorage,
  getRandomValueInRange,
  removeOnboardingKeysForMiddleFlow,
} from "configs/utils";

import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import Skin from "assets/images/Priorities/SmoothSkin.svg";
import Energy from "assets/images/Priorities/Energy.svg";
import Serenity from "assets/images/Priorities/Serenity.svg";
import FreshLook from "assets/images/Priorities/FreshLook.svg";
import GutHealth from "assets/images/Priorities/GutHealth.svg";
import NoCravings from "assets/images/Priorities/NoCravings.svg";
import Hair from "assets/images/Priorities/Hair.svg";
import Eating from "assets/images/Priorities/BingeEating.svg";
import Sleep from "assets/images/Priorities/Sleep.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { SidebarSteps } from "../data";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../OnBoardingPartThree.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const HealthScorePriorities = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const healthScoreData = useSelector((state) => state.auth.healthScoreData);
  const [button1, setButton1] = useState(
    // healthScoreData.button1
    false
  );
  const [button2, setButton2] = useState(false);
  const [button3, setButton3] = useState(false);
  const [button4, setButton4] = useState(false);
  const [button5, setButton5] = useState(false);
  const [button6, setButton6] = useState(false);
  const [button7, setButton7] = useState(false);
  const [button8, setButton8] = useState(false);
  const [button9, setButton9] = useState(false);
  const [button10, setButton10] = useState(false);

  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    const HIP = getUserHealthScoreDataFromLocalStorage()?.healthScorePriorities;

    if (!HIP) return;

    setButton1(HIP?.button1);
    setButton2(HIP?.button2);
    setButton3(HIP?.button3);
    setButton4(HIP?.button4);
    setButton5(HIP?.button5);
    setButton6(HIP?.button6);
    setButton7(HIP?.button7);
    setButton8(HIP?.button8);
    setButton9(HIP?.button9);
    setButton10(HIP?.button10);
  }, []);

  const prioritiesButtonData = [
    {
      id: "button.priority.moreBeautifulSkin",
      selected: button1,
      setSelected: setButton1,
      img: Skin,
    },
    {
      id: "button.priority.moreEnergy.everydayLife",
      selected: button2,
      setSelected: setButton2,
      img: Energy,
    },
    {
      id: "button.priority.innerSerenity",
      selected: button3,
      setSelected: setButton3,
      img: Serenity,
    },
    {
      id: "button.priority.freshAppearance",
      selected: button4,
      setSelected: setButton4,
      img: FreshLook,
    },
    {
      id: "button.priority.healthierHair",
      selected: button5,
      setSelected: setButton5,
      img: Hair,
    },
    {
      id: "button.priority.moreRestfulSleep",
      selected: button6,
      setSelected: setButton6,
      img: Sleep,
    },
    // {
    //   id: "button.priority.moreBeautifulSkin",
    //   selected: button7,
    //   setSelected: setButton7,
    //   img: Skin,
    // },
    {
      id: "button.priority.noHungerPangs",
      selected: button8,
      setSelected: setButton8,
      img: NoCravings,
    },
    {
      id: "button.priority.noBingeEating",
      selected: button9,
      setSelected: setButton9,
      img: Eating,
    },
    {
      id: "button.priority.balancedDiet",
      selected: button10,
      setSelected: setButton10,
      img: GutHealth,
    },
  ];

  const checkIfThreeItemsHaveSelected = () => {
    const selectedItems = prioritiesButtonData.filter((i) => i.selected);
    if (selectedItems.length === 3) return true;

    return false;
  };

  const handleBack = () => {
    history.push("/onboarding/healthscore/investigation");
  };

  const handleNext = () => {
    const isValid =
      [
        button1,
        button2,
        button3,
        button4,
        button5,
        button6,
        button7,
        button8,
        button9,
        button10,
      ].filter(Boolean).length === 3;

    console.log("isValid", isValid);

    if (!isValid) {
      SetIsValidation(true);
      return;
    }

    const prioritiesButtonStatus = {
      button1,
      button2,
      button3,
      button4,
      button5,
      button6,
      button7,
      button8,
      button9,
      button10,
      currentHealthScore: getRandomValueInRange(40, 45),
    };
    setUserHealthScoreDataInLocalStorage({
      ...getUserHealthScoreDataFromLocalStorage(),
      healthScorePriorities: prioritiesButtonStatus,
    });
    // dispatch(AuthActions.setHealthScoreData(prioritiesButtonStatus));
    history.push("/onboarding/healthscore/anamnese-evaluation");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    history.push("/dashboard/main");
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={4} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Tipps & Tricks
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Grundlagen einer gesunden Ernährung.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-proirities-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Was wünscht du dir von einer gesunden Ernährung?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Dein körperliches Ziel haben wir bereits festgelegt, doch eine
                  gesunde Ernährung kann viel mehr!
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Wähle deine{" "}
                  {/* {prioritiesButtonData.filter((item) => item.selected).length}{" "} */}
                  3 Favoriten:
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte wähle eine Gewohnheit
                  </p>
                )}
                <div className="health-score-proirities-blocks gap-3">
                  <S.HealthScorePriorityButtonsContainer>
                    {prioritiesButtonData.map((buttonValues, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            buttonValues.selected
                              ? "priority-item active d-flex flex-column align-items-center gap-3"
                              : "priority-item d-flex flex-column align-items-center gap-3"
                          }
                          onClick={() => {
                            if (checkIfThreeItemsHaveSelected()) {
                              buttonValues.setSelected(false);
                              return;
                            }
                            SetIsValidation(false);
                            buttonValues.setSelected(!buttonValues.selected);
                          }}
                        >
                          <img src={buttonValues.img} alt="" />
                          <p className="text-size-14-17 text-light-grey-1 text-center font-rubik-regular my-0">
                            <TranslationMessage id={buttonValues.id} />
                          </p>
                        </div>
                      );
                    })}
                  </S.HealthScorePriorityButtonsContainer>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default HealthScorePriorities;
