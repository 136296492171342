import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  renderValueInGerman,
  removeOnboardingKeysForMiddleFlow,
} from "configs/utils";
import moment from "moment";
import axios from "axios";
import { HEADERS } from "configs/services/index";
import { BASE_URL } from "configs/services/apiCollections";

import TranslationMessage from "configs/translations/TranslationMessage";
import Switch from "components/formComponents/switch/Switch";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
import Button from "components/formComponents/button/Button";

import ArrowLeft from "assets/images/arrowLeft.svg";

import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "../OnBoardingPartThree.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const UserSubscriptionsDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");

  const [isChecked, setIsChecked] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [Discount, setDiscount] = useState(
    localStorage.getItem("discount") || 0
  );

  const registrationData = useSelector((state) => state.auth.registrationData);
  const authData = useSelector((state) => state.auth);
  console.log("authData.trainerData", authData?.trainerData);

  const [isPaymentSubmitting, setIsPaymentSubmitting] = useState(false);
  const [oneTimePayment, setOneTimePayment] = useState(false);
  const [code, setCode] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const TotalDiscountValue = user === "Pro" ? 450.0 : 150.0;

  useEffect(() => {
    setDiscount(localStorage.getItem("discount"));

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const makePayment = () => {
    const userRegistrationAllData = JSON.parse(
      localStorage.getItem("RegistrationData")
    );

    const registeredUser = JSON.parse(
      localStorage.getItem("RegisteredUserDetails")
    );
    const trainerID = localStorage.getItem("trainerID");

    const userID = registeredUser?.["$nutritionconcept"]?.userID;

    console.log("userRegistrationAllData", userRegistrationAllData, userID);

    const formData = {
      currency: "eur",
      amount: oneTimePayment ? 194.85 : 64.95,
      userID: userRegistrationAllData?.userID || userID,
      trainerID: authData?.trainerData?.trainerID || +trainerID,
      date: moment().format("YYYY-MM-DD"),
      subscribe_status: user === "Pro" ? "PRO-USER" : "PLUS-USER",
      payment_type: oneTimePayment ? "One-time payment" : "Monthly payment",
    };
    setIsPaymentSubmitting(true);
    dispatch(
      SupplementsActions.initiateStripePaymentRequest({
        formValues: formData,
        history,
        func: (response) => {
          if (response instanceof Error) {
            console.log("Error: ", response);
          } else {
            setIsPaymentSubmitting(false);
            conversionOnTapifiliate();
            hitGetUser();
            history.push("/onboarding/foodiary/family");
          }
        },
        affiliateData: {
          userID: userRegistrationAllData?.userID || userID,
          email: userRegistrationAllData?.email,
          conversionAmount: user === "Pro" ? 450 : 150,
          commissionType: user === "Pro" ? "coaching-pro" : "coaching-plus",
        },
      })
    );
  };

  const conversionOnTapifiliate = async () => {
    const userRegistrationAllData = JSON.parse(
      localStorage.getItem("RegistrationData")
    );

    const registeredUser = JSON.parse(
      localStorage.getItem("RegisteredUserDetails")
    );
    const trainerID = localStorage.getItem("trainerID");

    const userID = registeredUser?.["$nutritionconcept"]?.userID;

    const payload = {
      customer_id: userRegistrationAllData?.email,
      external_id: userRegistrationAllData?.userID || userID,
      amount: user === "Pro" ? 450 : 150,
      currency: "EUR",
      commission_type: user === "Pro" ? "coaching-pro" : "coaching-plus",
      commission_name: user === "Pro" ? "Coaching PRO" : "Coaching PLUS",
      meta_data: {
        trainerID: authData?.trainerData?.trainerID || +trainerID,
        fullname_coach:
          authData?.trainerData?.prename + " " + authData?.trainerData?.surname,
        email_coach: authData?.trainerData?.email || "",
        vorname_klient: userRegistrationAllData?.prename,
        nachname_klient: userRegistrationAllData?.surname,
        email_klient: userRegistrationAllData?.email,
        FranchiseID: authData?.trainerData?.FranchiseID,
      },
      user_agent: navigator.userAgent,
      ip: ipAddress,
      landing_page: window.location.href,
    };

    const tapfiliateHeaders = {
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "3acc178a1eff97b14cc1b12c00222e070a3a1b91",
      },
    };

    try {
      const response = await axios.post(
        "https://api.tapfiliate.com/1.6/conversions/",
        payload,
        tapfiliateHeaders
      );

      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hitGetUser = async () => {
    const userRegistrationAllData = JSON.parse(
      localStorage.getItem("RegistrationData")
    );

    const registeredUser = JSON.parse(
      localStorage.getItem("RegisteredUserDetails")
    );

    const userID = registeredUser?.["$nutritionconcept"]?.userID;

    try {
      const response = await axios.post(
        BASE_URL() + "user-info/create",
        { user_id: userRegistrationAllData?.userID || userID },
        {
          headers: {
            ...HEADERS,
          },
        }
      );
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBack = () => {
    history.push("/onboarding/subscriptions");
  };

  const handleNext = () => {
    makePayment();
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );

    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={5} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Start
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Gemeinsam gesunde Gewohnheiten aufbauen.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                  disabled={
                    !isChecked.firstCheck ||
                    !isChecked.secondCheck ||
                    isPaymentSubmitting
                  }
                >
                  Kostenpflichtig abschließen
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="user-subscriptions-details-content">
                <h3 className="text-size-20-28 text-dark-1 text-center font-rubik-medium mt-0">
                  Wie darf ich dich unterstützen?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 text-center font-rubik-regular">
                  3 Monate {user === "Pro" ? "persönliche" : "digitale"}{" "}
                  Unterstützung beim Aufbau deiner gesunden Gewohnheiten.
                </p>
                <S.SubscriptionDetailsContainer>
                  {/* <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                    <TranslationMessage
                      id={
                        user === "Pro"
                          ? "subscription.pro.page.heading"
                          : "subscription.plus.page.heading"
                      }
                    />
                  </h3>
                  <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                    <TranslationMessage id="subscription.details.subHeading" />
                  </p> */}

                  <S.SubscriptionDetailsProInnerDetailsOneTimePayment>
                    <span
                      style={{ color: oneTimePayment ? "grey" : "#2ECF96" }}
                    >
                      <TranslationMessage
                        style={{ marginRight: "50px;", color: "white;" }}
                        id="subscription.details.card1.pro.oneTimePayment"
                      />
                    </span>
                    <Switch
                      hideLabels
                      keepBackgroundSame
                      switchedOn={oneTimePayment}
                      onChange={() => setOneTimePayment(!oneTimePayment)}
                    />
                    <span
                      style={{ color: oneTimePayment ? "#2ECF96" : "grey" }}
                    >
                      <TranslationMessage
                        style={{ marginLeft: "50px;" }}
                        id="subscription.details.card1.pro.monthly"
                      />
                    </span>
                  </S.SubscriptionDetailsProInnerDetailsOneTimePayment>
                  <br />
                  <br />
                  <div className="subscription-details-card">
                    <S.SubscriptionDetailsProInnerContainer>
                      <h6 className="text-size-20-24 text-dark-1 my-0">
                        <TranslationMessage id="subscription.details.card1.heading" />
                      </h6>
                      <S.SubscriptionDetailsProInnerDetailsContainer
                        style={{ margin: "24px 0 20px" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <S.SubscriptionDetailsProInnerDetailsFlexColumn width="120px">
                            <S.SubscriptionDetailsProInnerIconContainer>
                              <span>{user}</span>
                            </S.SubscriptionDetailsProInnerIconContainer>
                          </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                          <S.SubscriptionDetailsProInnerDetailsFlexColumn width="250px">
                            <h6 className="text-size-18-22 text-dark-1 my-0">
                              <TranslationMessage
                                id={
                                  user === "Pro"
                                    ? "subscription.details.card1.pro.heading"
                                    : "subscription.details.card1.Plus.heading"
                                }
                              />
                            </h6>
                            <p className="text-size-14-17 text-light-grey">
                              <TranslationMessage
                                id={
                                  user === "Pro"
                                    ? "subscription.details.card1.pro.subHeading"
                                    : "subscription.details.card1.plus.subHeading"
                                }
                              />
                            </p>
                            <p className="text-size-14-17 text-dark-1 my-0">
                              <TranslationMessage id="subscription.details.card1.pro.description" />
                            </p>
                          </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                        </div>
                        <S.SubscriptionDetailsProInnerDetailsFlexColumn width="110px">
                          <S.SubscriptionDetailsProInnerDetailsCostWithBg>
                            {oneTimePayment && user === "Pro" && "450,00 €"}
                            {!oneTimePayment && user === "Pro" && "150,00 €"}
                            {oneTimePayment && user === "Plus" && "150,00 €"}
                            {!oneTimePayment && user === "Plus" && "49,66 €"}
                          </S.SubscriptionDetailsProInnerDetailsCostWithBg>
                          <p>
                            {oneTimePayment ? (
                              <TranslationMessage id="subscription.details.card1.pro.perThreeMonth" />
                            ) : (
                              <TranslationMessage id="subscription.details.card1.pro.perMonth" />
                            )}
                          </p>
                        </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                      </S.SubscriptionDetailsProInnerDetailsContainer>
                      <S.SubscriptionDetailsProInnerDetailsContainer>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <S.SubscriptionDetailsProInnerDetailsFlexColumn width="120px">
                            <S.SubscriptionDetailsStaticContainer>
                              <span>KRE</span>
                            </S.SubscriptionDetailsStaticContainer>
                            {/* <Input filled style={{width: '62px', margin: "10px 0 0 0", textAlign: 'center'}} placeholder="CODE" onChange={(e) => setCode(e.target.value)} value={code} /> */}
                          </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                          <S.SubscriptionDetailsProInnerDetailsFlexColumn width="250px">
                            <h6 className="text-size-18-22 text-dark-1 my-0">
                              <TranslationMessage id="subscription.details.card1.fix.heading" />
                            </h6>
                            <p className="text-size-14-17 text-light-grey">
                              <TranslationMessage id="subscription.details.card1.fix.subHeading" />
                            </p>
                            <p className="text-size-14-17 text-dark-1 my-0">
                              <TranslationMessage id="subscription.details.card1.fix.description" />
                            </p>
                          </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                        </div>
                        <S.SubscriptionDetailsProInnerDetailsFlexColumn width="110px">
                          <S.SubscriptionDetailsProInnerDetailsCostWithBg>
                            {/* {`${Discount}`.replace(".", ",")},00 € */}
                            {renderValueInGerman(+Discount)}
                            {/* {Discount.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                          </S.SubscriptionDetailsProInnerDetailsCostWithBg>
                          <S.SubscriptionDetailsProInnerDetailsCostContainer>
                            {/* {TotalDiscountValue},00 €<span>-</span> */}
                          </S.SubscriptionDetailsProInnerDetailsCostContainer>
                        </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                      </S.SubscriptionDetailsProInnerDetailsContainer>
                      <br />
                      <br />
                      <div className="divider" />
                      <S.SubscriptionDetailsTotalContainer>
                        <h6 className="text-size-20-24 text-dark-1 my-0">
                          Gesamt
                        </h6>
                        <S.SubscriptionDetailsTotalContainerValueColumn>
                          <h6 className="text-size-20-24 text-green my-0">
                            {renderValueInGerman(TotalDiscountValue - Discount)}
                          </h6>
                          <p className="text-size-14-17 text-light-grey text-right mt-1 mb-0">
                            inkl. MwSt.
                          </p>
                        </S.SubscriptionDetailsTotalContainerValueColumn>
                      </S.SubscriptionDetailsTotalContainer>
                    </S.SubscriptionDetailsProInnerContainer>
                  </div>
                  {/* <br />
                  <div className="subscription-details-card">
                    <S.SubscriptionDetailsProInnerContainer>
                      <S.SubscriptionDetailsPromoContainer>
                        <S.SubscriptionDetailsPromoHeadings>
                          <h5 className="text-size-14-18 text-green">
                            Gutschein
                          </h5>
                          <h6 className="text-size-18-28 text-dark-1">
                            Ich habe einen Gutschein
                          </h6>
                        </S.SubscriptionDetailsPromoHeadings>
                        <S.SubscriptioninputBtnContainer>
                          <S.SubscriptionDataInputContainer>
                            <input
                              className="input-w-bg"
                              type="text"
                              placeholder="Gutscheincode eingeben..."
                              name="franchiseId"
                            />
                          </S.SubscriptionDataInputContainer>
                          <Button
                            color="rgba(46, 207, 150, .2)"
                            gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                            style={{ fontSize: "16px" }}
                          >
                            Anwenden
                          </Button>
                        </S.SubscriptioninputBtnContainer>
                      </S.SubscriptionDetailsPromoContainer>
                    </S.SubscriptionDetailsProInnerContainer>
                  </div> */}
                  <br />
                  <div className="subscription-details-card">
                    <S.SubscriptionDetailsProInnerContainer>
                      <S.SubscriptionDetailsRulesContainer>
                        {/* <div style={{ display: "flex" }}>
                          <SquareCheckbox
                            isChecked={isChecked.firstCheck}
                            param="firstCheck"
                            setIsChecked={setIsChecked}
                            rounded="5px"
                            filled="#F5F5F5"
                          />
                          <S.SubscriptionDetailsRulesLabel>
                            <TranslationMessage id="subscription.details.card2.checkBox1" />
                          </S.SubscriptionDetailsRulesLabel>
                        </div> */}

                        <div className="checkbox-group d-flex gap-2">
                          <SquareCheckbox
                            isChecked={isChecked.firstCheck}
                            param="firstCheck"
                            setIsChecked={setIsChecked}
                            rounded="5px"
                          />
                          <p className="text-size-16-24 text-dark-1 font-rubik-regular my-0">
                            <TranslationMessage id="subscription.details.card2.checkBox1" />
                          </p>
                        </div>
                        <br />
                        <div className="checkbox-group d-flex gap-2">
                          <SquareCheckbox
                            isChecked={isChecked.secondCheck}
                            param="secondCheck"
                            setIsChecked={setIsChecked}
                            rounded="5px"
                          />
                          <p className="text-size-16-24 text-dark-1 font-rubik-regular my-0">
                            Der Teilnehmer hat die{" "}
                            <a
                              className="text-dark-1"
                              href="https://foodiary.app/terms/agb-coaching/"
                              target="_blank"
                            >
                              AGB Coaching
                            </a>{" "}
                            und die{" "}
                            <a
                              target="_blank"
                              className="text-dark-1"
                              href="https://foodiary.app/terms/datenschutz/"
                            >
                              Datenschutzbestimmungen
                            </a>{" "}
                            gelesen, verstanden und akzeptiere diese.
                          </p>
                        </div>

                        {/* <div style={{ display: "flex" }}>
                          <SquareCheckbox
                            isChecked={isChecked.secondCheck}
                            param="secondCheck"
                            setIsChecked={setIsChecked}
                            rounded="5px"
                            filled="#F5F5F5"
                          />
                          <S.SubscriptionDetailsRulesLabel></S.SubscriptionDetailsRulesLabel>
                        </div> */}
                      </S.SubscriptionDetailsRulesContainer>
                    </S.SubscriptionDetailsProInnerContainer>
                  </div>
                </S.SubscriptionDetailsContainer>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default UserSubscriptionsDetails;
