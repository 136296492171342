import { useEffect } from "react"
import * as S from "./newDashboard.styles"
import { format } from "date-fns"
import dayjs from 'dayjs'

function getCurrentMonthActiveClients (currentMonthActiveClientsData) {
  if (!currentMonthActiveClientsData) return null
  return currentMonthActiveClientsData.reduce((acc, curr) => {
    const proCount = parseInt(curr['PRO-USER'])
    const plusCount = parseInt(curr['PLUS-USER'])
    if (isNaN(proCount) || isNaN(plusCount)) return acc
    return acc += proCount + plusCount
  }, 0)
}

const formatDateString = (date) => (
  date ? format(new Date(date), "dd.MM.yyyy") : ""
)

function LeftTop ({
  activeClientsCurrentMonthChartData,
  ActiveClientsPercentage,
  invoiceData,
  currentMonthTrainerLeads,
  lastMonthTrainerLeads,
  dates
}) {
  const lastFromCurrentMonthsLeads = `${currentMonthTrainerLeads - lastMonthTrainerLeads}`

  const currentMonthClients = getCurrentMonthActiveClients(activeClientsCurrentMonthChartData)
  const previousMonthSalesAmount = invoiceData.total_amount_transferred || 0
  const startDate = formatDateString(dates.startDate)
  const endDate = formatDateString(dates.endDate)

  const currentMonthLeadsToClientsRate = (
    !currentMonthClients || !currentMonthTrainerLeads
    ? '0'
    : `${(currentMonthClients / currentMonthTrainerLeads * 100).toFixed(2)}`
  )

  return (
    <S.DashboardLefttop>
      <S.StatsCard>
        <div className="stats-card-header">
          <span>Erhaltene Leads</span>
          <span>Aktueller Monat</span>
        </div>
        <div className="stats-card-content">
          <div>
            <span>Total</span>
            <span>{currentMonthTrainerLeads}</span>
          </div>
          <div>
            <span className={lastFromCurrentMonthsLeads.includes('-') ? "warning-percentage" : "green-percentage"}>
              {lastFromCurrentMonthsLeads}
            </span>
            <span>Veränderung zum Vormonat</span>
          </div>
        </div>
      </S.StatsCard>
      <S.StatsCard>
        <div className="stats-card-header">
          <span>Klienten</span>
          <span>Aktueller Monat</span>
        </div>
        <div className="stats-card-content">
          <div>
            <span>Total</span>
            <span>{currentMonthClients || 0}</span>
          </div>
          <div>
            <span className={currentMonthLeadsToClientsRate.includes('-') ? "warning-percentage" : "green-percentage"}>
              {currentMonthLeadsToClientsRate}%
            </span>
            <span>Konversionsrate zu Leads</span>
          </div>
        </div>
      </S.StatsCard>
      <S.StatsCard>
        <div className="stats-card-header">
          <span>Auszahlungsbetrag</span>
          <span>{dayjs(startDate, 'DD-MM-YYYY').format('DD-MM').replace('-', '.')} - {dayjs(endDate, 'DD-MM-YYYY').format('DD-MM').replace('-', '.')}</span>
        </div>
        <div className="stats-card-content">
          <div>
            <span>Total</span>
            <span>{previousMonthSalesAmount}€</span>
          </div>
          <div>
            <span className="invisible">invisible</span>
            <span>Anstehende Auszahlung</span>
          </div>
        </div>
      </S.StatsCard>
    </S.DashboardLefttop>
  )
}

export default LeftTop
