import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeOnboardingKeysForMiddleFlow } from "configs/utils";

import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import GreenCheck from "assets/images/GreenCheck.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { SidebarSteps } from "../data";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "../OnBoardingPartThree.styles";

const HealthScoreImprovements = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const CardData = [
    {
      id: "card1",
      heading: "healthScore.improvement.card.heading.1",
      benefits: [
        "healthScore.improvement.card.minerals.benefits1",
        "healthScore.improvement.card.minerals.benefits2",
        "healthScore.improvement.card.minerals.benefits3",
        "healthScore.improvement.card.minerals.benefits4",
      ],
    },
    {
      id: "card2",
      heading: "healthScore.improvement.card.heading.2",
      benefits: [
        "healthScore.improvement.card.vitamins.benefits1",
        "healthScore.improvement.card.vitamins.benefits2",
        "healthScore.improvement.card.vitamins.benefits3",
        "healthScore.improvement.card.vitamins.benefits4",
      ],
    },
    {
      id: "card3",
      heading: "healthScore.improvement.card.heading.3",
      benefits: [
        "healthScore.improvement.card.fattyAcids.benefits1",
        "healthScore.improvement.card.fattyAcids.benefits2",
        "healthScore.improvement.card.fattyAcids.benefits3",
        "healthScore.improvement.card.fattyAcids.benefits4",
      ],
    },
  ];

  const handleBack = () => {
    history.push("/onboarding/healthscore/tips-and-tricks");
  };

  const handleNext = () => {
    history.push("/onboarding/healthscore/premium-coaching-features");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    history.push("/dashboard/main");
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={4} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Tipps & Tricks
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Grundlagen einer gesunden Ernährung.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-improvements-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Die Grundlage deiner täglichen Ernährung
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Mikronährstoffe und Vitamine unterstützen wichtige Funktionen
                  im Körper.
                </p>
                <br />
                <div className="health-score-improvements">
                  <S.HealthScoreImprovementCardContainer>
                    {CardData.map((card) => {
                      return (
                        <div className="health-improvement-card" key={card.id}>
                          <h4 className="text-size-18-27 text-dark-1 mt-0">
                            <TranslationMessage id={card.heading} />
                          </h4>
                          {card.benefits.map((benefit, i) => {
                            return (
                              <S.BenefitsContainer key={i}>
                                <S.ChecklistElementGreenTickContainer>
                                  <img src={GreenCheck} alt="GreenCheck" />
                                </S.ChecklistElementGreenTickContainer>
                                <p className="text-size-16-24 text-dark-2 font-rubik-regular my-0">
                                  <TranslationMessage id={benefit} />
                                </p>
                              </S.BenefitsContainer>
                            );
                          })}
                        </div>
                      );
                    })}
                  </S.HealthScoreImprovementCardContainer>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default HealthScoreImprovements;
