import styled from "styled-components";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;

export const Wrapper = styled.div`
  padding: 1.5rem;
`;

export const InlineThreeControls = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 1rem;
`;

export const InlineControls = styled.div`
  display: flex;
  // gap: 1rem;

   > * {
    flex: 1;
    margin-right: 10px !important;
   }
`

export const Explanation = styled.div`
  font-size: 14px;
  margin: 5px 0px;
  a {
    color: #229E88;
  }
`

export const FormControl = styled.div`
  position: relative;
  margin: 10px 0px;
  // border: 1px solid black;

  img {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 12px;
    transform: translateY(-23px);
  }
`;

export const TrainerProfileDetailsSectionHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrainerProfileDetailsSectionSubHeading = styled.div`
  font-size: 16px;
  color: #818d88;
  font-weight: 400;
  margin: 1.2rem 0 2.5rem;
  line-height: 19px;
`;

export const TrainerProfileDetailsSectionDescription = styled.div`
  font-size: 14px;
  color: #818d88;
  font-weight: 400;
  margin-bottom: 10px;
`

export const SuccessAlert = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const ErrorAlert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const TrainerProfileDetailsInnerContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
`;

export const TrainerProfileDetailsSection1InputLabel = styled.div`
  font-size: 0.875rem;
  color: #44554c;
  line-height: 1.5rem;
  margin: 0 0 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  textarea {
    border: 1px solid #dddddd;
    border-radius: 4px;
    width: 100% !important;
    background-color: transparent;
  }

  img {
    position: relative;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
