import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Slider from "react-slick";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { getS3ImageUrl } from "configs/utils";
import { ToastContainer, toast } from "react-toastify";

import Modal from "components/modal/Modal";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross.svg";
import CopyIcon from "assets/images/copy-icon-black.svg";
import CongratulationsHands from "assets/images/Loading-Success.png";
import Sanduhr from "assets/images/Loading-Sanduhr.png";

import { MENUS } from "./SideMenu";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./MarketingPortal.styles";

const PrintDataDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [
    IsSetAdvertisingPartnersAndPictureModal,
    SetIsSetAdvertisingPartnersAndPictureModal,
  ] = useState(false);
  const [SelectedPartner, SetSelectedPartner] = useState(null);
  const [PrintsTagOptions, SetPrintsTagOptions] = useState([]);
  const [IsSuccessMessage, SetIsSuccessMessage] = useState(false);
  const [IsCreatePrintProcessing, SetIsCreatePrintProcessing] = useState(false);
  const [CreatePartnerPrintLinkToOpen, SetCreatePartnerPrintLinkToOpen] =
    useState("");

  const trainerData = useSelector((state) => state.auth.trainerData);

  const isMarketingPortalPrintsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPrintsLoading
  );

  const marketingPortalPrints = useSelector(
    (state) => state.nutrition.marketingPortalPrintsDetails
  );

  const isMarketingPortalPartnersLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPartnersLoading
  );

  const marketingPortalPartners = useSelector(
    (state) => state.nutrition.marketingPortalPartners
  );

  const isMarketingPortalCoachDetailsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalCoachDetailsLoading
  );

  const marketingPortalCoachDetails = useSelector(
    (state) => state.nutrition.marketingPortalCoachDetails
  );

  const isMarketingPortalPrintPicLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPrintPicLoading
  );

  const marketingPortalPrintPic = useSelector(
    (state) => state.nutrition.marketingPortalPrintPic
  );

  const isMarketingPortalRawUrlByPrintIdLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalRawUrlByPrintIdLoading
  );

  const marketingPortalRawUrlByPrintId = useSelector(
    (state) => state.nutrition.marketingPortalRawUrlByPrintId
  );

  const isMarketingPortalPrintPlacementDetailsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPrintPlacementDetailsLoading
  );

  const marketingPortalPrintPlacementDetails = useSelector(
    (state) => state.nutrition.marketingPortalPrintPlacementDetails
  );

  const isMarketingPortalPrintCreating = useSelector(
    (state) => state.nutrition.isMarketingPortalPrintCreating
  );

  const marketingPortalCreatePrint = useSelector(
    (state) => state.nutrition.marketingPortalCreatePrint
  );

  const isMarketingPortalCreatePartnerPrintLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalCreatePartnerPrintLoading
  );

  const marketingPortalCreatePartnerPrint = useSelector(
    (state) => state.nutrition.marketingPortalCreatePartnerPrint
  );

  const printDetails = marketingPortalPrints?.getPrintDetailsById?.[0] || null;

  console.log("marketingPortalPartners", marketingPortalPartners);
  console.log("marketingPortalCoachDetails", marketingPortalCoachDetails);
  console.log("marketingPortalPrintPic", marketingPortalPrintPic);
  console.log("marketingPortalRawUrlByPrintId", marketingPortalRawUrlByPrintId);
  console.log(
    "marketingPortalPrintPlacementDetails",
    marketingPortalPrintPlacementDetails
  );
  console.log("marketingPortalCreatePrint", marketingPortalCreatePrint);

  useEffect(() => {
    getMarketingPortalPrintsDetails();

    dispatch(
      NutritionActions.getMarketingPortalPartnersRequest({
        payload: {
          connectedCoachID: trainerData?.trainerID,
        },
        callback: (res) => {
          SetPrintsTagOptions(
            res?.getPartnerListData?.map((item) => ({
              ...item,
              label: item?.companyName,
              value: item?.partner_id,
            }))
          );
        },
      })
    );
  }, [dispatch]);

  const getMarketingPortalPrintsDetails = () => {
    dispatch(
      NutritionActions.getMarketingPortalPrintsDetailsRequest({
        payload: {
          print_id: id,
        },
        callback: (res) => {},
      })
    );
  };

  const Slides = {
    0: <img src={printDetails?.previewImg1} alt="" />,
    1: <img src={printDetails?.previewImg2} alt="" />,
    2: <img src={printDetails?.previewImg3} alt="" />,
  };

  let settings = {
    customPaging: function (i) {
      return Slides[i];
    },
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const onSelectPartner = (b) => {
    console.log("onSelectPartner", b);
    SetSelectedPartner(b);
  };

  const createAndDownloadAdvertisingMaterial = () => {
    if (!SelectedPartner) return;

    SetIsCreatePrintProcessing(true);
    dispatch(
      NutritionActions.marketingPortalCoachDetailsRequest({
        payload: {
          coachId: trainerData?.trainerID,
        },
        callback: (coachDetails) => {
          dispatch(
            NutritionActions.marketingPortalPrintPicRequest({
              payload: {
                trainerID: trainerData?.trainerID,
              },
              callback: (printPicData) => {
                dispatch(
                  NutritionActions.marketingPortalRawUrlByPrintIdRequest({
                    payload: {
                      print_id: id,
                    },
                    callback: (res) => {
                      dispatch(
                        NutritionActions.marketingPortalPrintPlacementDetailsRequest(
                          {
                            payload: {
                              print_id: id,
                            },
                            callback: (res) => {
                              const assets =
                                res?.getPrintPlacementDetailsByPrintId
                                  .map((item) => {
                                    if (item.type === "printPic") {
                                      return {
                                        ...item,
                                        data: printPicData?.printPic,
                                      };
                                    }
                                    if (item.type === "qrCodeUrl") {
                                      return {
                                        ...item,
                                        data: SelectedPartner?.qrCodeUrl,
                                      };
                                    }
                                    if (item.type === "coachname") {
                                      return {
                                        ...item,
                                        data: `${coachDetails?.coachData?.prename} ${coachDetails?.coachData?.surname}`,
                                      };
                                    }
                                    if (item.type === "coachmobilenumber") {
                                      return {
                                        ...item,
                                        data: coachDetails?.coachData
                                          ?.Handynummer,
                                      };
                                    }
                                    if (item.type === "coachmail") {
                                      return {
                                        ...item,
                                        data: coachDetails?.coachData
                                          ?.foodiaryEmail,
                                      };
                                    }
                                    return { ...item, data: `` };
                                  })
                                  .map((item) => ({
                                    id: item.id,
                                    data: item.data,
                                  }));

                              console.log("assets", assets);

                              dispatch(
                                NutritionActions.marketingPortalCreatePrintRequest(
                                  {
                                    payload: {
                                      print_id: +id,
                                      assets,
                                    },
                                    callback: (createPrintRes) => {
                                      console.log(
                                        "marketingPortalCreatePrintRequest",
                                        createPrintRes
                                      );
                                      SetCreatePartnerPrintLinkToOpen(
                                        getS3ImageUrl(createPrintRes?.path)
                                      );
                                      dispatch(
                                        NutritionActions.marketingPortalCreatePartnerPrintRequest(
                                          {
                                            payload: {
                                              printId: +id,
                                              partnerId:
                                                SelectedPartner?.partner_id,
                                              file_url: getS3ImageUrl(
                                                createPrintRes?.path
                                              ),
                                            },
                                            callback: (
                                              marketingPortalCreatePartnerPrintRes
                                            ) => {
                                              console.log(
                                                "marketingPortalCreatePartnerPrintRes",
                                                marketingPortalCreatePartnerPrintRes
                                              );

                                              SetIsSuccessMessage(true);
                                              SetSelectedPartner(null);
                                            },
                                          }
                                        )
                                      );
                                    },
                                  }
                                )
                              );
                            },
                          }
                        )
                      );
                    },
                  })
                );
              },
            })
          );
        },
      })
    );
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        
        <S.Wrapper className="second-sidebar-content">
          {isMarketingPortalPrintsLoading && (
            <>
              <br />
              <br />
              <br />
              <br />
              <div className="text-center">
                <div className="loader mx-auto"></div>
              </div>
              <br />
              <br />
              <br />
              <br />
            </>
          )}
          {!isMarketingPortalPrintsLoading && printDetails && (
            <S.PrintDataDetails className="bg-white">
              <S.TrainerProfileDetailsInnerContainer className="p-0">
                <S.Header>
                  <div>
                    <S.BackButton
                      onClick={() =>
                        history.push("/dashboard/marketing-portal/print-data")
                      }
                    >
                      <NavigateBeforeIcon />
                      <span>Zurück zur Übersicht</span>
                    </S.BackButton>
                  </div>
                  <div className="header-actions">
                    <button
                      className="btn-outline sm"
                      onClick={() =>
                        window.open(printDetails?.testfileURL, "_blank")
                      }
                    >
                      Testdaten herunterladen
                    </button>
                    <button
                      className="btn-dark"
                      onClick={() =>
                        SetIsSetAdvertisingPartnersAndPictureModal(true)
                      }
                    >
                      Werbemittel erstellen
                    </button>
                  </div>
                </S.Header>
                <S.Divider />
              </S.TrainerProfileDetailsInnerContainer>

              <S.DetailsInfo>
                <div className="d-flex gap-1 relative">
                  <S.TrainerProfileDetailsSectionHeading>
                    {printDetails?.title}
                  </S.TrainerProfileDetailsSectionHeading>
                  <div>
                    <div className="tag-label little-up">
                      {printDetails?.tag}
                    </div>
                  </div>
                </div>

                <S.TrainerProfileDetailsSectionSubHeading>
                  {printDetails?.beschreibung}
                </S.TrainerProfileDetailsSectionSubHeading>

                <S.DetailsGrid>
                  <div className="slider-container">
                    <Slider {...settings}>
                      <div className="slide-item">
                        <img src={printDetails?.previewImg1} alt="" />
                      </div>
                      <div className="slide-item">
                        <img src={printDetails?.previewImg2} alt="" />
                      </div>
                      <div className="slide-item">
                        <img src={printDetails?.previewImg3} alt="" />
                      </div>
                    </Slider>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="info-items">
                    <S.InfoItem>
                      <span className="title">Werbeset ID</span>
                      <span className="value">{printDetails?.setID}</span>
                    </S.InfoItem>
                    <S.InfoItem className="column">
                      <span className="title">Produktlink:</span>
                      <div className="second-line">
                        <span className="value text-truncate">
                          {printDetails?.productlink}
                        </span>
                        <img
                          className="copy-icon"
                          src={CopyIcon}
                          alt="CopyIcon"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              printDetails?.productlink
                            );
                            toast("📃 Erfolgreich kopiert.");
                          }}
                        />
                      </div>
                    </S.InfoItem>
                    <S.InfoItem>
                      <span className="title">Stückzahl:</span>
                      <span className="value">{printDetails?.pices}</span>
                    </S.InfoItem>
                    <S.InfoItem>
                      <span className="title">Preis:</span>
                      <span className="value">{printDetails?.price}</span>
                    </S.InfoItem>
                  </div>
                </S.DetailsGrid>
              </S.DetailsInfo>
            </S.PrintDataDetails>
          )}
        </S.Wrapper>
      </S.Container>
      <Modal
        width="600px"
        height="auto"
        show={IsSetAdvertisingPartnersAndPictureModal}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            {!IsCreatePrintProcessing && !IsSuccessMessage && (
              <h4 className="my-0 text-left">Werbepartner festlegen</h4>
            )}

            {IsCreatePrintProcessing && IsSuccessMessage && (
              <h4 className="my-0 text-left"></h4>
            )}

            {(!IsCreatePrintProcessing || IsSuccessMessage) && (
              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => {
                  SetIsCreatePrintProcessing(false);
                  SetIsSuccessMessage(false);
                  SetIsSetAdvertisingPartnersAndPictureModal(false);
                }}
              />
            )}
          </div>
        }
      >
        {" "}
        <div className="congratulations-modal-body-inner">
          {!IsSuccessMessage && IsCreatePrintProcessing && (
            <img src={Sanduhr} alt="" />
          )}
          {IsSuccessMessage && IsCreatePrintProcessing && (
            <img src={CongratulationsHands} alt="" />
          )}
          {!IsCreatePrintProcessing && (
            <>
              <S.FormControl>
                <S.TrainerProfileDetailsSection1InputLabel className="mb-1">
                  Partner auswählen
                </S.TrainerProfileDetailsSection1InputLabel>
                <Select
                  options={PrintsTagOptions}
                  className="faq-select"
                  classNamePrefix="react-select"
                  onChange={onSelectPartner}
                  value={SelectedPartner}
                  placeholder="Alle Partner"
                  isSearchable={false}
                  isDisabled={
                    isMarketingPortalCoachDetailsLoading ||
                    isMarketingPortalPrintPicLoading ||
                    isMarketingPortalRawUrlByPrintIdLoading ||
                    isMarketingPortalPrintPlacementDetailsLoading ||
                    isMarketingPortalPrintCreating ||
                    isMarketingPortalCreatePartnerPrintLoading
                  }
                />
              </S.FormControl>
              <br />
            </>
          )}
          {/* <S.FormControl>
          <S.TrainerProfileDetailsSection1InputLabel className="mb-1">
            Picture
          </S.TrainerProfileDetailsSection1InputLabel>
          <Input
            className="input-custom"
            style={{ width: "100%", marginBottom: "10px" }}
            placeholder=""
          />
          <img className="upload-icon" src={UploadIcon} alt="CopyIcon" />
        </S.FormControl> */}
          {isMarketingPortalCoachDetailsLoading && (
            <>
              <h6>Wir laden deine Coach Informationen.</h6>
              <p>Schließe das Fenster nicht.</p>
            </>
          )}

          {isMarketingPortalPrintPicLoading && (
            <>
              <h6>Wir laden dein Werbemittel.</h6>
              <p>Schließe das Fenster nicht.</p>
            </>
          )}

          {isMarketingPortalRawUrlByPrintIdLoading && (
            <>
              <h6>Wir laden dein Profilbild.</h6>
              <p>Schließe das Fenster nicht.</p>
            </>
          )}

          {isMarketingPortalPrintPlacementDetailsLoading && (
            <>
              <h6>Wir laden die Platzierungen.</h6>
              <p>Schließe das Fenster nicht.</p>
            </>
          )}

          {(isMarketingPortalPrintCreating ||
            isMarketingPortalCreatePartnerPrintLoading) && (
            <>
              <h6>Dein Werbemittel wird erstellt.</h6>
              <p>Schließe das Fenster nicht.</p>
            </>
          )}

          {IsSuccessMessage && (
            <>
              <h6>Fertig!</h6>
              <p>Du kannst das Werbemittel nun herunterladen.</p>
            </>
          )}

          <div className="text-center">
            {IsSuccessMessage && (
              <button
                className="btn-dark mx-auto"
                onClick={() =>
                  window.open(CreatePartnerPrintLinkToOpen, "_blank")
                }
              >
                Werbemittel herunterladen
              </button>
            )}

            {!IsCreatePrintProcessing && (
              <button
                className="btn-dark mx-auto"
                onClick={createAndDownloadAdvertisingMaterial}
                disabled={
                  isMarketingPortalCoachDetailsLoading ||
                  isMarketingPortalPrintPicLoading ||
                  isMarketingPortalRawUrlByPrintIdLoading ||
                  isMarketingPortalPrintPlacementDetailsLoading ||
                  isMarketingPortalPrintCreating ||
                  isMarketingPortalCreatePartnerPrintLoading
                }
              >
                Werbemittel herunterladen
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrintDataDetails;
