import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";
import moment from "moment";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross-icon-faq.svg";
import SearchIcon from "assets/images/search.svg";
import clapNotice from "assets/images/clap-notice.svg"
import CheckIcon from 'assets/images/check-icon.svg'

import { MENUS } from "./SideMenu";
import * as S from "./MarketingPortal.styles";
import * as SupplementsActions from "redux/supplements/supplements.actions";

const FoodiaryLeads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [SelectedList, SetSelectedList] = useState("Verfügbare Leads");

  const isFoodiaryLeadsLoading = useSelector(
    (state) => state.supplements.isFoodiaryLeadsLoading
  );
  const foodiaryLeads = useSelector((state) => state.supplements.foodiaryLeads);
  const leadAuthorization = useSelector(
    (state) => state.supplements.leadAuthorization
  );
  const isLeadAuthorizationLoading = useSelector(
    (state) => state.supplements.isLeadAuthorizationLoading
  );

  const leadHistory = useSelector((state) => state.supplements.leadHistory);
  const isLeadHistoryLoading = useSelector(
    (state) => state.supplements.isLeadHistoryLoading
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  const leadCenterStatus = useSelector(
    (state) => state.supplements.leadCenterStatus
  )

  useEffect(() => {
    dispatch(
      SupplementsActions.getFoodiaryLeadsRequest({
        callback: (res) => { },
      })
    );

    dispatch(
      SupplementsActions.getLeadAuthorizationRequest({
        payload: {
          trainer_id: trainerData?.trainerID,
        },
        callback: (res) => { },
      })
    );

    dispatch(
      SupplementsActions.getLeadHistoryRequest({
        payload: {
          trainer_id: trainerData?.trainerID,
          year: new Date().getFullYear(),
        },
        callback: (res) => { },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!trainerData) return
    dispatch(
      SupplementsActions.getLeadCenterStatusRequest({
        payload: { trainer_id: trainerData?.trainerID }
      })
    )
  }, [trainerData])

  const showLeadCenterNotice = !leadCenterStatus?.getleadcenterstatus
  const isLoading = (isFoodiaryLeadsLoading || isLeadAuthorizationLoading || isLeadHistoryLoading)

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Leads von Foodiary
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Schalte Leads von Foodiary frei und vereinbare direkt ein
                  Erstgespräch.
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
              <div>{/* <button className="btn-dark">Speichern</button> */}</div>
            </S.Header>

            <S.Divider />
            <br />

            {isLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
            <br />

            { !isLoading && showLeadCenterNotice ? <GoPremiumNotice /> : '' }

            {!isLoading && !showLeadCenterNotice && (
                <>
                  <div className="container-70rem">
                    <S.CardsWrap>
                      <div className="credit-card">
                        <div className="header">
                          <h6 className="font-rubik-regular">
                            Deine Leads der Woche
                          </h6>
                          <div className="date-range">
                            {moment(
                              new Date(leadAuthorization?.current_week?.start)
                            ).format("DD.MMMM")}{" "}
                            -{" "}
                            {moment(
                              new Date(leadAuthorization?.current_week?.end)
                            ).format("DD MMMM YYYY")}
                          </div>
                        </div>
                        <div className="body">
                          <div className="income">
                            <h4 className="text-green">
                              {+leadAuthorization?.current_week?.max_leads_to_claim
                                -
                                +leadAuthorization?.current_week?.claimed_leads
                              }
                            </h4>
                            <div className="income-status d-flex flex-column">
                              <h4>
                                {leadAuthorization?.current_week?.claimed_leads} /{" "}
                                {leadAuthorization?.current_week?.max_leads_to_claim}
                              </h4>
                              <span>Gesamte Leads</span>
                            </div>
                          </div>
                          <span className="income-title">
                            Verbleibende Leads
                          </span>
                        </div>
                      </div>

                      <div className="credit-card">
                        <div className="header">
                          <h6 className="font-rubik-regular">
                            Deine Leads in diesem Monat
                          </h6>
                          <div className="date-range">
                            {moment(
                              new Date(leadAuthorization?.current_month?.start)
                            ).format("MMMM")}
                          </div>
                        </div>
                        <div className="body">
                          <div className="income">
                            <h4 className="text-green">
                              {+leadAuthorization?.current_month?.max_leads_to_claim
                                -
                                +leadAuthorization?.current_month?.claimed_leads
                              }
                            </h4>
                            <div className="income-status d-flex flex-column">
                              <h4>
                                {leadAuthorization?.current_month?.claimed_leads} /{" "}
                                {leadAuthorization?.current_month?.max_leads_to_claim}
                              </h4>
                              <span>Gesamte Leads</span>
                            </div>
                          </div>
                          <span className="income-title">
                            Verbleibende Leads
                          </span>
                        </div>
                      </div>
                    </S.CardsWrap>
                    <br />
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className={
                          SelectedList === "Verfügbare Leads"
                            ? "chip-outline font-rubik-regular active"
                            : "chip-outline font-rubik-regular"
                        }
                        onClick={() => SetSelectedList("Verfügbare Leads")}
                      >
                        Verfügbare Leads
                      </div>
                      <div
                        className={
                          SelectedList === "Lead Verlauf"
                            ? "chip-outline font-rubik-regular active"
                            : "chip-outline font-rubik-regular"
                        }
                        onClick={() => SetSelectedList("Lead Verlauf")}
                      >
                        Lead Verlauf
                      </div>
                    </div>
                    <br />
                  </div>

                  {SelectedList === "Verfügbare Leads" && (
                    <S.TableWrapper className="container-70rem">
                      <table>
                        <thead>
                          <tr>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Eingegangen am:
                            </th>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Name
                            </th>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Zielsetzung
                            </th>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Alter
                            </th>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {foodiaryLeads?.foodiaryleadsData?.map((l) => (
                            <tr>
                              <td>
                                {l?.lead_received && (
                                  <p>
                                    {moment(new Date(l?.lead_received)).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </p>
                                )}
                              </td>

                              <td>
                                <p>
                                  {l?.prename} {l?.surename}
                                </p>
                              </td>
                              <td>{l?.lead_goal}</td>
                              <td>
                                <p>{l?.lead_age}</p>
                              </td>
                              <td>
                                <button
                                  className="btn-outline sm cursor-pointer"
                                  onClick={() => {
                                    history.push(
                                      `/dashboard/leads/foodiary/details/${l?.lead_id}`
                                    );
                                  }}
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </S.TableWrapper>
                  )}

                  {SelectedList === "Lead Verlauf" && (
                    <S.TableWrapper className="container-70rem">
                      <table>
                        <thead>
                          <tr>
                            <th className="text-light-grey-2 font-rubik-regular">
                              Monat:
                            </th>
                            <th className="text-light-grey-2 font-rubik-regular text-right">
                              Verwendete Leads
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {leadHistory?.data?.leads_history?.map((h) => (
                            <tr>
                              <td>
                                <p>
                                  {moment()
                                    .year(leadHistory?.data?.year)
                                    .month(h?.month - 1)
                                    .format("MMMM YYYY")}
                                </p>
                              </td>

                              <td className="text-right">
                                <p>{h?.utilization}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </S.TableWrapper>
                  )}
                </>
              )}
            <br />
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default FoodiaryLeads;

function GoPremiumNotice () {
  return (
    <S.GoPremiumNoticeContainer>
      <S.GoPremiumNoticeContent>
        <div className="content">
          <div>
            <h3 className="heading">Monatlich neue Coaching-Interessenten</h3>
            <div className="subheading">Sichere dir monatlich 10 neue Interessenten für dein Coaching, indem du die Funktion ‘Leads von Foodiary’ freischaltest.</div>
          </div>
          <div className="benefits_list">
            <h4>Was du bekommst:</h4>
            <div className="list">
            <div className="list-item">
              <span><img src={CheckIcon} alt="" /></span>
              <p>Jeden Monat stehen dir 10 neue Interessenten für dein Ernährungscoaching zur Verfügung. Du erhältst die Möglichkeit, bis zu 3 Interessenten pro Woche freizuschalten.</p>
            </div>
            <div className="list-item">
              <span><img src={CheckIcon} alt="" /></span>
              <p>Vereinbare ein Erstgespräch mit dem Interessenten und beginne danach dein Ernährungscoaching mit der Wahl zwischen Coaching PLUS und Coaching PRO.</p>
            </div>
          </div>
          </div>
          <div className="call_to_action">
            <h3>ausgebucht</h3>
            <span>pro Monat / jährliche Abrechnung</span>
            <a className="btn-dark" href="mailto:backoffice@foodiary.app">Freischalten</a>
          </div>
        </div>
        <div className="benefits_banner">
          <img src={clapNotice} alt="" />
        </div>
      </S.GoPremiumNoticeContent>
    </S.GoPremiumNoticeContainer>
  )
}
