import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeOnboardingKeysForMiddleFlow } from "configs/utils";

import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";

import premiumCoaching1 from "assets/images/premiumCoaching1.svg";
import premiumCoaching2 from "assets/images/premiumCoaching2.svg";
import premiumCoaching3 from "assets/images/premiumCoaching3.svg";
import premiumCoaching4 from "assets/images/premiumCoaching4.svg";
import premiumCoaching5 from "assets/images/premiumCoaching5.svg";
import premiumCoaching8 from "assets/images/premiumCoaching8.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";
import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

import { SidebarSteps } from "../data";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "../OnBoardingPartThree.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const PremiumCoachingFeatures = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const CardData = [
    {
      id: "card1",
      heading: "premium.coaching.benefit1",
      icon: premiumCoaching1,
    },
    {
      id: "card2",
      heading: "premium.coaching.benefit2",
      icon: premiumCoaching2,
    },
    {
      id: "card3",
      heading: "premium.coaching.benefit3",
      icon: premiumCoaching3,
    },
    {
      id: "card4",
      heading: "premium.coaching.benefit4",
      icon: premiumCoaching4,
    },
    {
      id: "card5",
      heading: "premium.coaching.benefit5",
      icon: premiumCoaching5,
    },
    // { id: "card6", heading: "premium.coaching.benefit6", icon: premiumCoaching6 },
    // {
    //   id: "card7",
    //   heading: "premium.coaching.benefit7",
    //   icon: premiumCoaching7,
    // },
    {
      id: "card8",
      heading: "premium.coaching.benefit8",
      icon: premiumCoaching8,
    },
  ];

  const handleBack = () => {
    history.push("/onboarding/healthscore/improvements");
  };

  const handleNext = () => {
    history.push("/onboarding/subscriptions");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    history.push("/dashboard/main");
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={5} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Start
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Gemeinsam gesunde Gewohnheiten aufbauen.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-premium-coachings-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Werde noch erfolgreicher
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Zertifiziert von den Deutschen Krankenkassen | Über 80.000
                  Klienten | Persönlich
                </p>
                <br />
                <div className="health-score-premium-coachings">
                  <div className="line-one">
                    {CardData.map((card, i) => {
                      return (
                        <div
                          className="premium-caoching-card d-flex flex-column align-items-center gap-3"
                          key={i}
                        >
                          <img className="img" src={card.icon} alt="cardIcon" />
                          <h4 className="text-size-16-24 text-dark-1 text-center my-0">
                            <TranslationMessage id={card.heading} />
                          </h4>
                        </div>
                      );
                    })}
                    <div className="premium-caoching-card">
                      <h4 className="text-size-16-24 text-dark-1 mt-0">
                        Unterstütz Durch:
                      </h4>
                      <div className="supported-brands">
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <img width={60} src={FoodIconOne} alt="cardIcon" />
                        </div>
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <img width={80} src={FoodIconTwo} alt="cardIcon" />
                        </div>
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <img width={80} src={FoodIconThree} alt="cardIcon" />
                        </div>
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <img width={100} src={FoodIconFour} alt="cardIcon" />
                        </div>
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <img width={80} src={FoodIconFive} alt="cardIcon" />
                        </div>
                        <div className="brand-item d-flex justify-content-center align-items-center">
                          <h5 className="text-size-16-20 text-center text-dark-1 my-0">
                            & viele weiter
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default PremiumCoachingFeatures;
